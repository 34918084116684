const dev = {
  STRIPE_KEY: "pk_test_cGtha6xU7Q8v43k5krogh8s100jPUebqTf",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-95dsh4aipifs"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://9t61tecbkd.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_HP63Pth6v",
    APP_CLIENT_ID: "5clpaeen74k3u4bthbfavnfugh",
    IDENTITY_POOL_ID: "us-east-1:e00cafdb-a19c-4d6c-8149-e2161e96eb40"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_cGtha6xU7Q8v43k5krogh8s100jPUebqTf",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-xx2j7qfzv25n"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://pi1yxp5p92.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_QjQWPLctb",
    APP_CLIENT_ID: "7nc07kbd73n04ifpl0vm9ki87h",
    IDENTITY_POOL_ID: "us-east-1:454b92d9-b095-495e-bd4e-0d744062d261"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};